<template>
  <div class="menu container has-text-centered">
     <div v-if="pending">
         <div class="columns">
             <div v-if="showSpecialOne || showSpecialTwo " class="column is-one-third">
                 <div v-if="showSpecialOne"  class="columns"> 
                    <div class="column">
                        <div class="notification is-success">
                             <h1 class="title"> {{specialOneTitle}}</h1>
                            <vue-simple-markdown :source="specialOne"></vue-simple-markdown>
                        </div>
                    </div>
                 </div>
                 <div v-if="showSpecialTwo" class="columns"> 
                    <div class="column">
                        <div class="notification is-info">
                             <h1 class="title"> {{specialTwoTitle}}</h1>
                            <vue-simple-markdown :source="specialTwo"></vue-simple-markdown>
                        </div>
                    </div>
                 </div>
                 
            </div>
            <div class="column ">
                <div class="">
                    <h1 class="title"> Speisekarte</h1>
                    <vue-simple-markdown :source="source"></vue-simple-markdown>
                </div>
            </div>
         </div>
     </div>
     <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
        </div>
  </div>
</template>

<script>
export default {
name:"menue",
data(){
    return {
        pending: false,
        menu: null
    }
},
computed: {
    showSpecialOne(){
        return this.menu.specialEinsAnzeigen
    },
    showSpecialTwo(){
        return this.menu.specialZweiAnzeigen
    },
    specialOneTitle(){
        return this.menu.SpecialEinsTitel
    },
    specialTwoTitle(){
        return this.menu.SpecialZweiTitel
    },
    specialOne(){
        return this.menu.SpecialEinsBeschreibung
    },
    specialTwo(){
        return this.menu.SpecialZweiBeschreibung
    },
    source(){
        return this.menu.Beschreibung
    },
},
async mounted() {
    this.pending = false
    this.menu = await this.$get("/menue")
    this.pending = true
}

}
</script>

<style scoped>
#austrianFlag {
    height: 100%;
}
</style>
