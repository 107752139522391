<template>
  <div class="weekMenu container">
    <h1 class="title">Wochenmenü</h1>
    <h2  v-if="pending" class="subtitle">von {{ rawData.startDatum }} bis {{ rawData.endDatum }}</h2>
      <div  v-if="pending" class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">   
          <b-table 
            :data="tableData" 
            :columns="columns"
          > </b-table>
          <br/>
            <div class="notification is-success">
              <h1 class="subtitle"> Wochenangebot</h1>
              <p>{{ rawData.WochenangebotBeschreibung }}</p>
              <p>{{ rawData.WochenangebotPreis }} €</p>
            </div>
        </div>
      </div>
       <div v-else class="is-three-fifths is-offset-one-fifth box">
            <h1 class="title">Loading . . .</h1>
        </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                rawData: null,
                pending: false,
                columns: [
                    {
                        field: 'day',
                        label: 'Tag',
                        width: '10',
                        centered: true
                    },
                    {
                        field: 'menu',
                        label: 'Menü',
                        centered: true
                    },
                    {
                        field: 'price',
                        label: 'Preis',
                        width: '10',
                        centered: true,
                        nummeric: true
                    }
                ]
            }
        }, 
        computed: {
          tableData(){
            let data = []
            let count = 1
              this.rawData.wochenmenues.forEach(element => {
              data.push(
                {
                  'id':count++,
                  'day': element.Tag,
                  'menu': element.Beschreibung,
                  'price': element.Preis + ' €'
                }
              )
            });
            return data
          }
        },
        async mounted(){
        this.reload()
        },
        methods:{
          async reload(){
            this.pending = false
            this.rawData = null
            this.rawData = await this.$get('/wochenmenu')
            this.pending = true
          }
        },
    }
</script>

<style>
</style>